<template>
  <div class="row">
    <div class="col-12">
      <card type="tasks" class="stacked-form">
        <div class="col-7">
          <div class="row">
            <div class="col-9">
              <base-input>
                <label>Operação Fiscal:</label>
                <el-select v-model="fulfillmentConfiguration.TaxOperation" placeholder="Selecione o tipo de operação"
                  :disabled="disabledField" @change="changeDescription" filterable>
                  <el-option v-for="item in typeOptions" :key="item.Key" :label="item.Label" :value="item.Key">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-3 d-flex align-items-center justify-content-center">
              <base-input>
                <label>Status:</label><br />
                <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                  v-model="fulfillmentConfiguration.Active" :sync="true" />
              </base-input>
            </div>
            <div class="col-12">
              <base-input>
                <label>Descrição:</label>
                <el-input v-model="fulfillmentConfiguration.Description" disabled />
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <base-input>
                <label>ID do Marketplace:
                  <el-tooltip content="Informe o ID do Marketplace no Platz. Encontre na URL ao editar o Marketplace."
                    effect="light" :open-delay="100" placement="top">
                    <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
                  </el-tooltip>
                </label>
                <el-input v-model="fulfillmentConfiguration.MarketplaceId" placeholder="Informe o ID Platz"
                  :disabled="disabledField" type="number"/>
              </base-input>
            </div>
            <div class="col-9">
              <base-input>
                <label>Nome do Marketplace:</label>
                <el-input v-model="fulfillmentConfiguration.MarketplaceName"
                  placeholder="Informe um nome para a conta do Marketplace" :disabled="disabledField" />
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label>Documentos a gerar:</label>
            </div>
            <div class="col-2">
              <base-input>
                <label>Picking:</label>
                <el-tooltip content="Definir nas Configurações Gerais por qual sistema o picking será gerado no ERP." effect="light" :open-delay="100"
                  placement="top">
                  <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
                </el-tooltip>
                <br />
                <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                  v-model="fulfillmentConfiguration.GeneratePicking" :sync="true" />
              </base-input>
            </div>
            <div class="col-2">
              <base-input>
                <label>Nota Fiscal de Saída:</label>
                <el-tooltip content="Aplicável para Remessa Avulsa, Remessa de Envio, Remessa para Transferência e Venda Consumidor."
                  effect="light" :open-delay="100" placement="top">
                  <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
                </el-tooltip>
                <br />
                <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                  v-model="fulfillmentConfiguration.GenerateSaleInvoice" :sync="true" />
              </base-input>
            </div>
            <div class="col-2">
              <base-input>
                <label>Dev. Nota Fiscal de Saída:</label>
                <el-tooltip content="Aplicável para Retorno para Transferência e Retorno Avulso."
                  effect="light" :open-delay="100" placement="top">
                  <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
                </el-tooltip>
                <br />
                <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                  v-model="fulfillmentConfiguration.GenerateReturnInvoice" :sync="true" />
              </base-input>
            </div>
            <div class="col-2">
              <base-input>
                <label>Transferência de Estoque:</label>
                <el-tooltip content="Realiza a transferência entre depósito de terceiros e venda." effect="light"
                  :open-delay="100" placement="top">
                  <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
                </el-tooltip>
                <br />
                <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                  v-model="fulfillmentConfiguration.GenerateStockTransfer" :sync="true"/>
              </base-input>
            </div>
            <div class="col-2">
              <base-input>
                <label>Contas a receber:</label>
                <el-tooltip content="Aplicável apenas para Nota de venda" effect="light" :open-delay="100"
                  placement="top">
                  <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
                </el-tooltip>
                <br />
                <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                  v-model="fulfillmentConfiguration.GenerateIncomingPayments" :sync="true" />
              </base-input>
            </div>
            <div class="col-2">
              <base-input>
                <label>Contas a pagar:</label>
                <el-tooltip content="Aplicável apenas para Devolução de Venda" effect="light" :open-delay="100"
                  placement="top">
                  <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
                </el-tooltip>
                <br />
                <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                  v-model="fulfillmentConfiguration.GenerateVendorPayments" :sync="true" />
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input>
                <label>ID da Filial dos documentos</label>
                <el-input v-model="fulfillmentConfiguration.BPLId" v-bind:maxlength="maxChar"
                          placeholder="Informe o ID correspondente" />
              </base-input>
            </div>
            <div class="col-12">
              <base-input>
                <label>ID da Sequência para Nota Fiscal de Saída e Dev. Nota Fiscal de Saída:</label>
                <el-input v-model="fulfillmentConfiguration.SequenceCode" v-bind:maxlength="maxChar"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  placeholder="Informe o ID correspondente" type="number"/>
              </base-input>
            </div>
            <div class="col-12">
              <base-input>
                <label>ID do Modelo para Nota Fiscal de Saída e Dev. Nota Fiscal de Saída:</label>
                <el-input v-model="fulfillmentConfiguration.SequenceModel" v-bind:maxlength="maxChar"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  placeholder="Informe o ID correspondente" type="number"/>
              </base-input>
            </div>
          </div>
        </div>
      </card>
      <FulfillmentConfigurationCondition ref="conditions" :conditions="fulfillmentConfiguration.Conditions" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { EventBus } from "@/eventBus";
import constants from '@/util/constants';
import FulfillmentConfigurationCondition from './FulfillmentConfigurationCondition.vue';

export default {
  computed: {
    ...mapState("fulfillment", ["fulfillmentConfigurationCurrent"]),
    ...mapState(["ecommerce"]),
  },
  data() {
    return {
      fulfillmentConfiguration: {
        EcommerceName: "",
        Description: "",
        TaxOperation: null,
        MarketplaceId: null,
        MarketplaceName: "",
        Active: false,
        GeneratePicking: false,
        GenerateSaleInvoice: false,
        GenerateStockTransfer: true,
        IntegrateIncomingPayments:false,
        IntegrateOutgoingPayments:false,
        SequenceCode: null,
        SequenceModel: null,
        Conditions: []
      },
      typeOptions: [],
      disabledField: false,
      showModal: false,
      maxChar: 4
    };
  },
  methods: {
    save() {
      this.$emit("save");
    },
    validate() {
      if (this.$isNullOrEmpty(this.fulfillmentConfiguration.TaxOperation)) {
        this.$showError("Tipo de Operação obrigatório.");
        return false;
      }
      else if ((this.fulfillmentConfiguration.GenerateSaleInvoice || this.fulfillmentConfiguration.GenerateReturnInvoice) &&
        (this.$isNullOrEmpty(this.fulfillmentConfiguration.SequenceCode))) {
        this.$showError("ID da Sequência para Nota Fiscal de Saída e Dev. Nota Fiscal de Saída obrigatório.");
        return false;
      }
      else if ((this.fulfillmentConfiguration.GenerateSaleInvoice || this.fulfillmentConfiguration.GenerateReturnInvoice) &&
        (!this.fulfillmentConfiguration.SequenceModel || this.fulfillmentConfiguration.SequenceModel < 1)) {
        this.$showError("ID do Modelo para Nota Fiscal de Saída e Dev. Nota Fiscal de Saída obrigatório.");
        return false;
      }
      else if (this.$isNullOrEmpty(this.fulfillmentConfiguration.MarketplaceId)) {
        this.$showError("ID do Marketplace obrigatório.");
        return false;
      }
      else if (this.$isNullOrEmpty(this.fulfillmentConfiguration.MarketplaceName)) {
        this.$showError("Nome do Marketplace obrigatório.");
        return false;
      }
      else if (this.fulfillmentConfiguration.GenerateStockTransfer &&
        !this.fulfillmentConfiguration.Conditions.some(condition => condition.DocumentType === 9 && condition.WarehouseType === 1)) {
        this.$showError("Condição: Depósito de Origem obrigatória.");
        return false;
      }
      else if (this.fulfillmentConfiguration.GenerateStockTransfer &&
        !this.fulfillmentConfiguration.Conditions.some(condition => condition.DocumentType === 9 && condition.WarehouseType === 2)) {
        this.$showError("Condição: Depósito de Destino obrigatória.");
        return false;
      }
      else if (this.fulfillmentConfiguration.GenerateSaleInvoice &&
        !this.fulfillmentConfiguration.Conditions.some(condition => condition.DocumentType === 12)) {
        this.$showError("Condição: Nota fiscal de Saída obrigatória.");
        return false;
      }
      else if (this.fulfillmentConfiguration.GenerateReturnInvoice &&
        !this.fulfillmentConfiguration.Conditions.some(condition => condition.DocumentType === 11)) {
        this.$showError("Condição: Dev. Nota fiscal de Saída obrigatória.");
        return false;
      }
      else if (this.fulfillmentConfiguration.TaxOperation === 1 && !this.fulfillmentConfiguration.Conditions.some(condition => condition.DocumentType === 8)) {
        this.$showError("Condição: Pedido de Venda obrigatória, para configuração de Remessa para envio.");
        return false;
      }
      else if (this.fulfillmentConfiguration.GenerateIncomingPayments && this.fulfillmentConfiguration.TaxOperation !== 4) {
        this.$showError("Documento: Contas a receber aplicável apenas para operação fiscal Nota de venda.");
        return false;
      }
      else if (this.fulfillmentConfiguration.GenerateVendorPayments && this.fulfillmentConfiguration.TaxOperation !== 6) {
        this.$showError("Documento: Contas a pagar aplicável apenas para operação fiscal Devolução de venda.");
        return false;
      }
      else
        return true;
    },
    getValue() {
      return this.fulfillmentConfiguration;
    },
    async load() {
      this.fulfillmentConfiguration = this.fulfillmentConfigurationCurrent;
      if (this.fulfillmentConfigurationCurrent) {
        this.disabledField = true;
        this.fulfillmentConfiguration.SequenceCode = this.fulfillmentConfiguration.SequenceCode === 0 ? "" : this.fulfillmentConfiguration.SequenceCode;
        this.fulfillmentConfiguration.SequenceModel = this.fulfillmentConfiguration.SequenceModel === 0 ? "" : this.fulfillmentConfiguration.SequenceModel;
      }
    },
    loadTypeOptions() {
      this.typeOptions = constants.taxOperationOptions.filter(a => a.Key != 0);
    },
    showModalCondition() {
      this.showModal = true;
    },
    closeModalCondition() {
      this.showModal = false;
    },
    changeDescription(type) {
      switch (type) {
        case 0:
          this.fulfillmentConfiguration.Description = ""
          break;
        case 1:
          this.fulfillmentConfiguration.Description = "Envio de estoque para o fulfillment"
          break;
        case 2:
          this.fulfillmentConfiguration.Description = "Retorno simbólico para transferência entre CDs do fulfillment"
          break;
        case 3:
          this.fulfillmentConfiguration.Description = "Remessa simbólica para transferêncio entre CDs do fulfillment"
          break;
        case 4:
          this.fulfillmentConfiguration.Description = "Nota de Venda fulfillment"
          break;
        case 5:
          this.fulfillmentConfiguration.Description = "Retorno simbólico de venda fulfillment"
          break;
        case 6:
          this.fulfillmentConfiguration.Description = "'Devolução de venda fulfillment"
          break;
        case 7:
          this.fulfillmentConfiguration.Description = "Remessa avulsa fulfillment"
          break;
        case 8:
          this.fulfillmentConfiguration.Description = "Retorno avulso fulfillment"
          break;
      }
    },
  },
  mounted() {
    EventBus.$on("loadFulfillmentConfiguration", async () => this.load());
    this.loadTypeOptions();
    this.typeOptions.sort((a, b) => {
      if (a.Label < b.Label) {
        return -1;
      }
      if (a.Label > b.Label) {
        return 1;
      }
      return 0;
    });
  },
  components: { FulfillmentConfigurationCondition }
}
</script>
<style>
.el-switch.is-checked .el-switch__core {
  border-color: #00ADB7 !important;
  background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
  color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
  color: #00ADB7;
  font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
  cursor: not-allowed !important;
}
</style>
